// Gatsby supports TypeScript natively!
import React from 'react';
import { PageProps, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Panel from '../components/panel';
import LinkOut from '../components/linkOut';
import TopPanel from '../components/topPanel';
import ButtonLink from '../components/buttonLink';

import HorizontalTitle from '../components/horizontalTitle';
import HorizontalButtonsContainer from '../components/horizontalButtonsContainer';
import VerticalButtonsContainer from '../components/verticalButtonsContainer';

import Map from '../images/Multi-Scale Integrated Cell (MuSIC) v1.png';
import styles from './pageStyles/index.module.css';


const contrastBackgroundColor = 'rgba(65,182,255, 0.25)';
const contrastTextColor = 'black';

const DonwloadData = (props: PageProps) => (
  <Layout>
    <SEO title='Download Data' />
   
    <TopPanel>
      <HorizontalTitle title='MuSIC Project' subtitle='Download MuSIC Data' />

    </TopPanel>

    <Panel   
    >
      <a id='data'>
        <h2>MuSIC Data</h2>
      </a>
      <ul> This page contains links for all of the data in each MuSIC map, including the released MuSIC maps and the underlying raw data used to create them. This page will be updated as new MuSIC maps are released.
      </ul>
    </Panel>    
    <Panel  backgroundColor={contrastBackgroundColor}
      textColor={contrastTextColor}
    >
      <a id='data'>
        <h2>U2OS MuSIC Map v1.0</h2>
      </a>
      <p>
        <ul>          
          <li>
          The U2OS MuSIC map 
          <ol>   The U2OS MuSIC map  contains 5147 proteins organized into 270 subsystems. This global map of subcellular structure was generated by integrating immunofluorescent images with biophysical interactions derived from AP-MS. Note: this unpublished map is provided as a resource.
</ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=1x7QDtVpe1uF5yqXlsROciF4edYDxm3Y_'> U2OS_MuSIC.cx
             </LinkOut>
             {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://www.ndexbio.org/viewer/networks/7baaa003-200c-11ee-aa50-005056ae23aa'> Download
             </LinkOut> */}
          </ol>
          </li>
          <li>
          U2OS MuSIC map subsystems. 
          <ol>   The 275 subsystems in the U2OS MuSIC map in tabular format. Each row represents a subsystem in the map with information on enrichment analyses, subsystem genes, and links to subnetworks. The second tab describes the information in each column.  </ol> 
          <ol> 
          <LinkOut link='https://docs.google.com/spreadsheets/d/1vo2BLr7o5k14QfuhmwGr1SoxYZ_MZBZp0pz9Plgg5Ug'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            <LinkOut link='https://docs.google.com/spreadsheets/d/1vo2BLr7o5k14QfuhmwGr1SoxYZ_MZBZp0pz9Plgg5Ug/export?format=xlsx'> Download
            </LinkOut>
          </ol>
          </li>
          <li>
            U2OS immunofluorescent images
          <ol> There are 20,660 immunofluorescent images for 10,348 proteins in the U2OS cell line. This file contains a link to every RGB image used in the U2OS MuSIC map and the corresponding gene name and antibody.  </ol> 
          <ol>
          <LinkOut link='https://docs.google.com/spreadsheets/d/1VgnboMiMqNO7kJRQb7Z1t73fnhbMRNDUJXiBZc36HEE/edit#gid=1156624013'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            <LinkOut link='https://drive.google.com/uc?export=download&id=1OKwQkUUXIicl_cq9EvzvtdLiBOtJT853'> Download
            </LinkOut>
            {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1g5ALFQwo7V3qds29MXwH7LPcQf-XSG3U'> Download .tsv
            </LinkOut> */}
          </ol>
          </li>
          <li>
          <li>
           U2OS biophysical interactions
          <ol>   The U2OS biophysical interaction network contains 36,872 edges between 7,546 unique proteins. This interaction network was derived from affinity purification-mass spectrometry experiments described in <LinkOut link='https://www.nature.com/articles/nature22366'> Huttlin et al.</LinkOut> </ol> 
          <ol>
            To be released
          </ol>
          </li>
          <li>
            Inputs to toolkit
          <ol> These are the files input to the {' '}<LinkOut link='https://cellmaps-pipeline.readthedocs.io/en/latest/'> cell mapping toolkit</LinkOut> used to construct the U2OS MuSIC map. The files include a list of antibodies to use for each protein (unique), information for image download (samples), protein interactions (edges), and protein interaction baits (baits).  </ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=1iIOCGGO0Z3VCB-fQZUtfaGZsvQgYPlI2'> U2OS_unique.csv
            </LinkOut>
            &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1QAvAeX5TLM98D_VF_NYyGPJd1gxrNeip'> U2OS_samples.csv
            </LinkOut>
          </ol>
          </li>
          </li>
          </ul>
      </p>
    </Panel>

    <Panel   
    >
      <a id='data'>
        <h2>HEK293 MuSIC Map v1.0</h2>
      </a>
      <p>
        <ul>  
        <li>
          The HEK293 MuSIC map 
          <ol>   The HEK293 MuSIC map contains 661 proteins organized into 69 subsystems. This global map of subcellular structure was generated by integrating immunofluorescent images with biophysical interactions derived from AP-MS. </ol> 
          <ol>
            <LinkOut link='https://drive.google.com/uc?export=download&id=14ARWf03S12bopg_rbuBrzD7cRGMyFXUL'> HEK293_MuSIC.cx
             </LinkOut>
             {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://www.ndexbio.org/index.html#/network/7fc70ab6-9fb1-11ea-aaef-0ac135e8bacf?accesskey=68afa0480a4859906b5d221619ee95679da96059680557f65c3dd9f1842e4930'> Download
             </LinkOut> */}
          </ol>
          </li>
          <li>
          HEK293 MuSIC map subsystems. 
          <ol>   The 69 subsystems in the U2OS MuSIC map in tabular format. </ol> 
          <ol> 
          <LinkOut link='https://docs.google.com/spreadsheets/d/1vBKdsl_9IrLrktsyWqUtwnFTQpN9eUC3/edit#gid=1223175131'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            <LinkOut link='https://drive.google.com/uc?export=download&id=1vBKdsl_9IrLrktsyWqUtwnFTQpN9eUC3'> Download
            </LinkOut>
          </ol>
          </li>
          <li>
            HEK293 immunofluorescent images
          <ol> There are 2,357 immunofluorescent images for 1,078 proteins in the HEK293 cell line. This file contains a link to every RGB image used in the HEK293 MuSIC map and the corresponding gene name and antibody.  </ol> 
          <ol>
          <LinkOut link='https://docs.google.com/spreadsheets/d/1n_YeKuCSqMVvbkNZgjleAMgMbGOm7kIajjzy18kT4aw/edit#gid=214321126'> View
            </LinkOut>
            &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=13R9j1evTb-b-horecB2BuEISggH8O7Kd'> Download
            </LinkOut>
            {/* &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1bfNYnhlG5jqmHqlVTUSsZcA0iJNYg8vH'> HEK293_MuSIC_images.tsv
            </LinkOut> */}
          </ol>
          </li>
          <li>
           HEK293 biophysical interactions
          <ol>   The HEK293 biophysical interaction network contains 56,553 edges between 10,961 unique proteins. This interaction network was derived from affinity purification-mass spectrometry experiments described in <LinkOut link='https://www.nature.com/articles/nature22366'> Huttlin et al.</LinkOut> </ol> 
          <ol>
            <LinkOut link='https://www.ndexbio.org/viewer/networks/98ba6a19-586e-11e7-8f50-0ac135e8bacf'> View on NDEx
             </LinkOut>
             &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://drive.google.com/uc?export=download&id=1fSPbM0XWcSawJlXblenuxwAZh7A6ps4v'> HEK293_bioplex_edges.tsv
             </LinkOut>
          </ol>
          </li>
          {/* <li>
            Inputs to toolkit
          <ol> These are the files input to the {' '}<LinkOut link='https://cellmaps-pipeline.readthedocs.io/en/latest/'> cell mapping toolkit</LinkOut> used to construct the HEK293 MuSIC map. The files include a list of antibodies to use for each protein (unique), information for image download (samples), protein interactions (edges), and protein interaction baits (baits).  </ol> 
          <ol>
            <LinkOut link='https://www.ndexbio.org/index.html#/network/7baaa003-200c-11ee-aa50-005056ae23aa?accesskey=68afa0480a4859906b5d221619ee95679da96059680557f65c3dd9f1842e4930'> unique.csv
            </LinkOut>
            &nbsp;
            &nbsp;
            {' '}
            <LinkOut link='https://www.ndexbio.org/index.html#/network/7baaa003-200c-11ee-aa50-005056ae23aa?accesskey=68afa0480a4859906b5d221619ee95679da96059680557f65c3dd9f1842e4930'> samples.csv
            </LinkOut>
          </ol>
          </li> */}
          </ul>
      </p>
    </Panel>    
  
  </Layout>
);

export default DonwloadData;
